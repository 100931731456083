import Keycloak, { KeycloakInitOptions } from 'keycloak-js';

let authenticated = false;
let user = {};

const keycloakInstance = new Keycloak(
  process.env.REACT_APP_ENV === 'DEVELOPMENT'
    ? '/keycloak-test.json'
    : '/keycloak.json',
);

const getInitOptions = ({
  checkLoginIframe = false,
  token = '',
}): KeycloakInitOptions => {
  return {
    onLoad: 'login-required',
    checkLoginIframe,
    ...(!!token && { token }),
  };
};

const init = async () => {
  try {
    authenticated = await keycloakInstance.init(
      getInitOptions({ checkLoginIframe: true }),
    );
    return authenticated;
  } catch {
    return false;
  }
};

const tryAuthenticated = async () => {
  try {
    authenticated = await keycloakInstance
      .init(getInitOptions({}))
      .then((authenticated) => authenticated);
    return authenticated;
  } catch {
    return false;
  }
};

const loginWithToken = async (token) => {
  try {
    authenticated = await keycloakInstance
      .init(getInitOptions({ token }))
      .then((authenticated) => authenticated);

    return authenticated;
  } catch {
    return false;
  }
};

const getProfile = async () => {
  try {
    user = await keycloakInstance.loadUserProfile().then((profile) => profile);
    return user;
  } catch {
    return false;
  }
};

const getRefreshToken = () => keycloakInstance.refreshToken;

const getToken = () => {
  return keycloakInstance.token;
};

const isLogged = () => !!keycloakInstance.token;

const login = async () => {
  try {
    authenticated = await keycloakInstance
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
      })
      .then((authenticated) => authenticated)
      .catch((error) => {
        return false;
      });
    return authenticated;
  } catch (error) {
    return false;
  }
};

const logout = () => keycloakInstance.logout();

const register = () => keycloakInstance.register();

const updatePassword = () => {
  return keycloakInstance.login({
    redirectUri: '/passe-carros',
    action: 'UPDATE_PASSWORD',
  });
};

const updateToken = () => keycloakInstance.updateToken(50);

export {
  getProfile,
  getRefreshToken,
  getToken,
  init,
  isLogged,
  keycloakInstance,
  login,
  loginWithToken,
  logout,
  register,
  tryAuthenticated,
  updatePassword,
  updateToken,
};

export default keycloakInstance;
